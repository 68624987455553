import { useState, useEffect } from 'react';
import { Row, Col, Input, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  OutlinedInput,
	Select,
	MenuItem,
	ListItemText,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import useLock from 'src/hooks/useLock'
import useBonds from "src/hooks/Bonds"
import Lock from 'src/assets/imgs/lock.svg'

import IdoLogo from 'src/assets/imgs/ido-logo.svg'
import IdoIcon from 'src/assets/imgs/ido-icon.svg'
import IdoUSDT from 'src/assets/imgs/ido-usdt.svg'

import "./ido.scss";
import "./ido.app.scss";

export default function Ido() {

	const { t, i18n } = useTranslation();
  const isSmallerScreen = useMediaQuery("(max-width: 1200px)");

	const { bonds } = useBonds();
  const [bond, setBond] = useState(bonds[0])
	const [selectValue, setSelectValue] = useState('usdt')

  const [inputValue, setInputValue] = useState()
	const [coundown, setCoundown] = useState({ day: '00', hour: '00', minutes: '00', seconds: '00' })
	const { endLockTime, vestingTerm, oldBalance, swapAllowance, users, calculatorReward, pendingPayoutFor, DoApprove, DoSwap, DoClaim, DoRedeem } = useLock()

	useEffect(() => {
		if (endLockTime) {
			setInterval(() => {
				let now = new Date().getTime()
				let startTime = endLockTime - now / 1000
				if (startTime > 0) {
					setCoundown(calTime(startTime))
				} else {
					let endTime = (+endLockTime + +vestingTerm) - now / 1000
					if (endTime > 0) {
						setCoundown(calTime(endTime))
					}
				}
			}, 1000)
		}
	}, [endLockTime])

	const setMax = () => {
    let maxQ;
    if (bond.maxBondPrice * bond.bondPrice < Number(bond.balance)) {
      maxQ = bond.maxBondPrice * bond.bondPrice.toString();
    } else {

      maxQ = bond.name == 'wAVAX' ? bond.balance[selVal + 1] : bond.balance[selVal];
    }
    setInputValue(maxQ);
  }

	const useStyles = makeStyles({
		input: {
			'& .MuiOutlinedInput-root': {
				'& MuiOutlinedInput': {
					borderColor: 'red !important', // 这里设置你想要的边框颜色
				},
				'&:hover MuiOutlinedInput': {
					borderColor: 'yellow !important', // 也可以设置hover时的颜色
				},
				'&.Mui-focused MuiOutlinedInput': {
					borderColor: 'green !important', // 设置焦点时的颜色
				},
			},
		},
	});
	
	return (
		<div className={`ido-view ${isSmallerScreen ? 'ido-view-app' : ''}`}>

			<div className='flex flex-column' style={{ marginRight: isSmallerScreen ? 0 : 20 }}>
				<div className='bg-container ido-operate'>
					<div className='ido-btn flex flex-justify-content-center flex-align-items-center'>IDO</div>

					<div className='operate-container'>

							<div className='input-container'>
								<OutlinedInput
									fullWidth
									// className="ido-input"
									className={useStyles.input}
									placeholder='0.00'
									type="number"
									value={inputValue}
									onChange={e => setInputValue(e.target.value)}
									MenuProps={{
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left"
										},
										transformOrigin: {
											vertical: "top",
											horizontal: "left"
										},
										getContentAnchorEl: null
									}}
									endAdornment={
										<Select value={selectValue}>
											<MenuItem key="usdt" value="usdt">
												<div className='ido-select-item flex flex-align-items-center'>
													<img src={IdoUSDT} alt="" />
													<ListItemText>USDT</ListItemText>
												</div>
											</MenuItem>
										</Select>
									}
									// endAdornment={
									// 	<InputAdornment position="end">
									// 		<Button variant="text" onClick={setMax} className="max-button">
									// 			Max
									// 		</Button>
									// 	</InputAdornment>
									// }
								/>
							</div>

							<div className='my-balance'>
								Balance: 2,520.34
							</div>
						</div>

					<div className='approve-btn flex flex-justify-content-center flex-align-items-center'>
            <img src={Lock} alt="" />
						<span>Approve</span>
					</div>
				</div>

				<div className='info-container bg-container'>
					<div className='ido-rule'>IDO Rules</div>
					<div className='goal-container'>
						<div className='goal'>Fundraise Goal</div>
						<div className='goal-usdt'>
							<span className='value'>
								{
									new Intl.NumberFormat("en-US", {
										// style: "currency",
										currency: "USD",
										maximumFractionDigits: 0,
										minimumFractionDigits: 0,
									}).format(500000)
								}
								{/* {stakingTVL || stakingTVL === 0 ?
									(
										new Intl.NumberFormat("en-US", {
											style: "currency",
											currency: "USD",
											maximumFractionDigits: 0,
											minimumFractionDigits: 0,
										}).format(stakingTVL)
									) : (
										<Skeleton width="100px" />
								)} */}
							</span>
							<img src={IdoUSDT} alt="" />
							<span className='usdt'>USDT</span>
							</div>
					</div>
					<div className='grid-container'>
						<div class="grid-item item1">
							<div className="title">Your participation</div>
							<div className="value">0.00</div>
						</div>
						<div class="grid-item item2">
							<div className="title">Participants</div>
							<div className="value">273</div>
						</div>
						<div class="grid-item item3">
							<div className="title">Fund Raised</div>
							<div className="value flex flex-align-items-end">
								<span>4,818,273</span>
								<span className='percent'>93.36%</span>
							</div>
						</div>
						<div class="grid-item item4">
							<div className="title">Node Status</div>
							<div className="value">
								<div className='not-tag flex flex-align-items-center flex-justify-content-center'>NOT</div>
							</div>
						</div>
					</div>
				</div>

			</div>

			<div className='flex flex-column'>

				<div className='bg-container timecount'>
					<div className="time-container flex flex-column flex-align-items-center">
						<div className="flex flex-justify-content-center saleStarts-text">
							CIGR's first round of fundraising ended in：
						</div>
						<Row justify="center" align="middle" className="countDown">
							<Row justify="center" align="middle" className="countItem flex-direction-column">
								<Col className="time countItem-text">{coundown.day}</Col>
								<div className="time-item">{t('ido.day')}</div>
							</Row>
							<span className="countItem-text countItem-text-symbol">:</span>
							<Row justify="center" align="middle" className="countItem flex-direction-column">
								<Col className="time countItem-text">{coundown.hour}</Col>
								<div className="time-item">{t('ido.hour')}</div>
							</Row>
							<span className="countItem-text countItem-text-symbol">:</span>
							<Row justify="center" align="middle" className="countItem flex-direction-column">
								<Col className="time countItem-text">{coundown.minutes}</Col>
								<div className="time-item">{t('ido.minute')}</div>
							</Row>
							<span className="countItem-text countItem-text-symbol">:</span>
							<Row justify="center" align="middle" className="countItem flex-direction-column">
								<Col className="time countItem-text">{coundown.seconds}</Col>
								<div className="time-item">{t('ido.second')}</div>
							</Row>
						</Row>
					</div>
				</div>

				<div className='ido-timeline bg-container'>
					<div className='timeline-logo flex flex-align-items-center'>
						<img src={IdoLogo} alt="" />
						<span>CIGR.IO</span>
					</div>

					<div className='timeline-title'>
						Congrats! You'veregistered to the lDO
					</div>

					<div className='timeline-subtitle'>
						Through blockchain technology, we will create the world's most fair
					</div>

					<div className='timeline-container'>

						<div className='timeline-start flex flex-align-items-center'>
							<div className='start-circle flex flex-align-items-center flex-justify-content-center'>
								<img src={IdoIcon} alt="" />
							</div>
							<span>CIGR IDO</span>

							<div className='gold-line'></div>
						</div>

						<div className='timeline-item timeline-item1'>
							<div className='timeline-circle'></div>

							<div>
								<div className='title'>Phase 1</div>
								<div className='text'>Through blockchain technology, we will create the world's most fair</div>
							</div>
						</div>

						<div className='timeline-item timeline-item2'>
							<div className='timeline-circle timeline-circle2'></div>

							<div>
								<div className='title'>Phase 2</div>
								<div className='text'>Through blockchain technology, we will create the world's most fair</div>
							</div>
						</div>

						<div className='timeline-item timeline-item3'>
							<div className='timeline-circle timeline-circle3 '></div>

							<div>
								<div className='title'>Phase 3</div>
								<div className='text'>Through blockchain technology, we will create the world's most fair</div>
							</div>
						</div>

					</div>
				</div>
			</div>

		</div>
	)
}
