import { useEffect, useState } from 'react';
import { useLocation, useHistory } from "react-router-dom"
import { Drawer, List, ListItem } from '@material-ui/core/';
import Logo from 'src/assets/imgs/top-logo.svg'
import Menu from 'src/assets/imgs/app-menu.svg'

import Arrow from 'src/assets/imgs/topApp/arrow_left.svg'
import X from 'src/assets/imgs/topApp/x.svg'
import Telegram from 'src/assets/imgs/topApp/telegram.svg'
import Docsend from 'src/assets/imgs/topApp/docsend.svg'
import Discord from 'src/assets/imgs/topApp/discord.svg'

import './index.scss'

const menuItems = [
  {
    display: 'IDO',
    path: 'ido',
  },
  {
    display: 'Airdrop',
    path: 'airdrop',
  },
  // {
  //   display: 'Dashboard',
  //   path: 'dashboard',
  // },
  // {
  //   display: 'Stake',
  //   path: 'stake',
  // },
  // {
  //   display: 'Donate',
  //   path: 'donate',
  // },
  // {
  //   display: 'Swap',
  //   path: 'swap',
  // },
]
export default function TopApp() {

  const { pathname } = useLocation()
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const [activeMenu, setActivemenu] = useState('dashboard')

  const handleSkip = (url) => {
    window.open(url)
  }

  const handleMenuClick = (item) => {
    history.push(`/${item.path}`)
    setActivemenu(item.path)
    setVisible(false)
  }

  useEffect(() => {
    if (pathname.indexOf("airdrop") >= 0) {
      setActivemenu('airdrop')
    }
    if (pathname.indexOf("ido") >= 0) {
      setActivemenu('ido')
    }
    if (pathname.indexOf("dashboard") >= 0) {
      setActivemenu('dashboard')
    }
    if (pathname.indexOf("stake") >= 0) {
      setActivemenu('stake')
    }
    if (pathname.indexOf("donate") >= 0) {
      setActivemenu('donate')
    }
    if (pathname.indexOf("swap") >= 0) {
      setActivemenu('swap')
    }
    if (pathname.indexOf("mall") >= 0) {
      setActivemenu('mall')
    }
  }, [pathname])

  return (
    <div className="top-app flex flex-justify-content-center flex-align-items-center">
      <div className='logo-container flex flex-align-items-center'>
        <img src={Logo} alt="" />
        <span>CIGR.IO</span>
      </div>

      <div className='top-menu'>
        <img src={Menu} alt="" onClick={() => setVisible(true)} />
      </div>

      <Drawer open={visible} onClose={() => setVisible(false)} className="drawer-container">
        <div className='menu-container'>
          <div className='close-icon'>
            <img src={Arrow} alt="" onClick={() => setVisible(false)} />
          </div>
          <List>
            {
              menuItems.map((item) => (
                <ListItem key={item.path}>
                  <div className={`menu-item ${activeMenu === item.path ? "menu-item-active" : ""}`} onClick={() => handleMenuClick(item)}>{item.display}</div>
                </ListItem>
              ))
            }
          </List>

          <div className='menu-bot'>
            <div className='menu-icon'>
              <img src={X} alt="" onClick={() => handleSkip('https://x.com/cigr_io')} />
              <img src={Telegram} alt="" onClick={() => handleSkip('https://t.me/cigr_chat')} />
              <img src={Discord} alt="" onClick={() => handleSkip('https://discord.gg/cigr')} />
              <img src={Docsend} alt="" onClick={() => handleSkip('https://docsend.com/v/rkrc3/cigr-black')} />
            </div>
            <div className='menu-ad'>© 2024 CIGR.iO all rights reserved.</div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}