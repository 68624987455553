
import './siderbar.scss'
import Folder from 'src/assets/imgs/folder.svg'
import Camera from 'src/assets/imgs/camera.svg'
import US from 'src/assets/imgs/us.svg'

export default function Siderbar() {

  return (
    <div className="siderbar">
      <div className="flex flex-align-items-center flex-justify-content-between flex-column" style={{ height: '100%' }}>
        <div>
          <div className="sider-item">
            <div className="sider-circle">
              <img src={Folder} alt="" />
            </div>
            <div className='text'>Balance</div>
          </div>
          <div className="sider-item">
            <div className="sider-circle">
              <img src={Camera} alt="" />
            </div>
            <div className='text'>Airdrop</div>
            </div>
          </div>

        {/* <div>
          <div className="sider-item">
            <img src={US} alt="" />
          </div>
        </div> */}
      </div>
    </div>
  )
}
