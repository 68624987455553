import { useEffect, useState } from 'react'
import { useLocation, useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next'

import './menu.scss'

export default function Menu() {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const history = useHistory()
  const [value, setValue] = useState('')

  useEffect(() => {
    if (pathname.indexOf("airdrop") >= 0) {
      setValue('airdrop')
    }
    if (pathname.indexOf("ido") >= 0) {
      setValue('ido')
    }
    if (pathname.indexOf("dashboard") >= 0) {
      setValue('dashboard')
    }
    if (pathname.indexOf("stake") >= 0) {
      setValue('stake')
    }
    if (pathname.indexOf("donate") >= 0) {
      setValue('donate')
    }
    if (pathname.indexOf("swap") >= 0) {
      setValue('swap')
    }
    if (pathname.indexOf("mall") >= 0) {
      setValue('mall')
    }
  }, [pathname])

  const handleClick = (path, ifOpen) => {
    if(ifOpen) return window.open(path)
    setValue(path)
    history.push(`/${path}`)
  }

  return (
    <div className='topbar-menu'>
      <div className='menu flex flex-justify-content-around'>
        <div className={`${value === 'ido' ? 'menu-item-active' : ''} menu-item`} onClick={() => handleClick('ido')}>IDO</div>
        <div className={`${value === 'airdrop' ? 'menu-item-active' : ''} menu-item`} onClick={() => handleClick('airdrop')}>Airdrop</div>
        {/* <div className={`${value === 'dashboard' ? 'menu-item-active' : ''} menu-item`} onClick={() => handleClick('dashboard')}>{t('menu.dashboard')}</div>
        <div className={`${value === 'stake' ? 'menu-item-active' : ''} menu-item`} onClick={() => handleClick('stake')}>Stake</div>
        <div className={`${value === 'donate' ? 'menu-item-active' : ''} menu-item`} onClick={() => handleClick('donate')}>Donate</div>
        <div className={`${value === 'swap' ? 'menu-item-active' : ''} menu-item`} onClick={() => handleClick('swap')}>Swap</div>
        <div className={`${value === 'mall' ? 'menu-item-active' : ''} menu-item`} onClick={() => handleClick('mall')}>Mall</div> */}
        
        {/* <div className={`${value === 'news' ? 'menu-item-active' : ''} menu-item`} onClick={() => handleClick('https://apepark.money/news?utm_source=tokenpocket',true)}>News</div> */}
      </div>
    </div>
  )
}
