import { useState } from "react";
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { Dropdown, Menu } from 'antd';

import Coming from '../Coming'

import Logo from 'src/assets/imgs/top-logo.svg'
import Telegram from 'src/assets/imgs/telegram.svg'
import Discord from 'src/assets/imgs/discord.svg'

import './footer.scss'

export default function Footer() {
  const { t, i18n } = useTranslation();
  const history = useHistory()
  const currentLang = i18n.language;

  const [showComing, setShowComing] = useState(false)

  const handleChange = (lang) => {
    i18n.changeLanguage(lang)
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={() => handleChange('en')}>English</Menu.Item>
      <Menu.Item onClick={() => handleChange('hk')}>中文</Menu.Item>
    </Menu>
  );

  const openUrl = (url) => {
    return
    if(url) return window.open(url)
    setShowComing(true)
  }

  return (
    <div id="footer-view">
      <Coming state={showComing} closeComing={() => setShowComing(false)} />

        <div className='footer-content'>

          <div className='footer-top'>
            <div className="footer-logo flex flex-align-items-center flex-justify-content-center">
              <img src={Logo} alt="logo" />
              <span>CIGR.IO</span>
            </div>

              <div className='desc'>
                Balance is a Web3 ecosystem dedicated to Web3 gaming, leveraging a user base of 260M to revolutionize the gaming industry with blockchain and AI technology.
              </div>

            {/* {isSmallScreen && <div className='right-item language flex'>
              <div className='title Poppins'>Language</div>
              <Dropdown 
                overlay={menu}
                trigger="hover"
                placement="bottom"
                overlayClassName={`lang-dropdown ${isSmallScreen ? 'lang-dropdown-app' : ''}`}
              >
                <div className="lang-select flex flex-justify-content-between flex-align-items-center">
                  <div>{currentLang === 'en' ? 'English' : '中文'}</div>
                  <img src={Arrow} alt="" />
                </div>
              </Dropdown>
            </div>} */}
          </div>

          <div className='footer-bot flex flex-justify-content-between'>

            <div className='right-item'>
              <div className='title'>Home</div>
                <div className="item" onClick={() => openUrl('/dashboard')}>About Us</div>
                <div className="item" onClick={() => openUrl('/unity')}>Advantage</div>
                <div className="item" onClick={() => openUrl('/bargain')}>Tokens</div>
            </div>

            <div className='right-item'>
              <div className='title'>Community</div>
                <div className="item" onClick={() => openUrl('https://apepark.money/launchpad')}>E-Pal</div>
                <div className="item" onClick={() => openUrl('https://apepark.money/play')}>Twitter</div>
                <div className="item" onClick={() => openUrl('https://apepark.money/play')}>Discord</div>
            </div>

            <div className='right-item'>
              <div className='title'>Support</div>
                <div className="item" onClick={() => openUrl('https://apepark.money/marketplace')}>Privacy</div>
                <div className="item" onClick={() => openUrl('https://apepark.money/marketplace')}>Times Of Services</div>
            </div>

            {/* {!isSmallScreen && <div className='right-item language flex'>
              <div className='title Poppins'>Language</div>

              <Dropdown 
                overlay={menu}
                trigger="hover"
                placement="bottom"
                overlayClassName={`lang-dropdown ${isSmallScreen ? 'lang-dropdown-app' : ''}`}
              >
                <div className="lang-select flex flex-justify-content-between flex-align-items-center">
                  <div>{currentLang === 'en' ? 'English' : '中文'}</div>
                  <img src={Arrow} alt="" />
                </div>
              </Dropdown>
            </div>} */}

          </div>
        </div>

        <div>
          <div className="footer-icon flex flex-align-items-center flex-justify-content-center">
            <img src={Telegram} alt="" />
            <img src={Discord} alt="" style={{ marginLeft: 20 }} />
          </div>

          <div className='footer-symbol flex flex-justify-content-center'>
            © 2024 balance game all rights reserved.
          </div>
        </div>
        </div>
  )
}
