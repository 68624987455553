import 'antd/dist/antd.css';

import { ThemeProvider } from "@material-ui/core/styles";
import { useEffect, useState, useCallback, lazy, Suspense } from "react";
import { Route, Redirect, Switch, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Hidden, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useTheme from "./hooks/useTheme";
import useBonds from "./hooks/Bonds";
import { useAddress, useWeb3Context } from "./hooks/web3Context";
import { storeQueryParameters } from "./helpers/QueryParameterHelper";
import { getQueryString } from 'src/utils'
import { calcBondDetails } from "./slices/BondSlice";
import { loadAppDetails, loadGovernanceDetails } from "./slices/AppSlice";
import { loadAccountDetails, calculateUserBondDetails, calculateUserSuperBondDetails } from "./slices/AccountSlice";

import { ChooseBond, TreasuryDashboard, Dashboard } from "./views";
import Siderbar from "./components/siderbar/Siderbar.jsx";

import TopMenu from "./components/TopMenu/TopMenu.jsx";
import TopApp from './components/TopApp'
import BotWallet from './components/BotWallet/BotWallet';
import Messages from "./components/Messages/Messages";
import Footer from "./components/Footer/Footer";
import NotFound from "./views/404/NotFound";
import { dark as darkTheme } from "./themes/dark.js";
import i18n from './react-i18next-config'

import "./style.scss";

const DEBUG = false;
const Ido = lazy(() => import('./views/Ido/Ido'));
const Airdrop = lazy(() => import('./views/Airdrop/Airdrop'));
const Donate = lazy(() => import('./views/Donate/Donate'));
const Donate1 = lazy(() => import('./views/Donate1/Donate'));
const Stake = lazy(() => import('./views/Stake/Stake'));
const Swap = lazy(() => import('./views/Swap/Swap'));

if (DEBUG) console.log(" Connecting to Mainnet Ethereum");

// const blockExplorer = targetNetwork.blockExplorer;

function App() {
  // useGoogleAnalytics();
  const dispatch = useDispatch();
  const [theme, toggleTheme] = useTheme();
  const location = useLocation();
  const history = useHistory()
  const [showPerformance, setShowPerformance] = useState(false);
  const [walletVisible, setWalletVisible] = useState(false);
  const [showComing, setShowComing] = useState(false)
  const isSmallerScreen = useMediaQuery("(max-width: 958px)");
  const isSmallScreen = useMediaQuery("(max-width: 1200px)");

  const { connect, hasCachedProvider, provider, chainID, connected } = useWeb3Context();
  const address = useAddress()
  const [walletChecked, setWalletChecked] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const { bonds } = useBonds();
  
  const drawerWidth = 200;
  const transitionDuration = 969;

  const useStyles = makeStyles(theme => ({
    drawer: {
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: transitionDuration,
      }),
      width: "100%",
      height: "100%",
      'overflow-x': 'none',
      margin: '0 auto',

      
      // 'overflow-y': 'auto',
      // overflow: "auto",
      // maxWidth: 1341,
      // width: 1341,
      // marginBottom: isSmallerScreen ? 66 : 0,
      // marginLeft: drawerWidth,
      // marginRight: isSmallerScreen ? 0 : 300,
      
      // paddingRight: 80
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: transitionDuration,
      }),
      marginLeft: 0,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
  }));
  const classes = useStyles();

  async function loadDetails(whichDetails) {
    if (whichDetails === "app") {
      loadApp(provider);
    }
    if (whichDetails === "account") {
      loadAccount(provider);
    }
    // if (whichDetails === "account" && address && connected && isLoad) {
    //   loadAccount();
    // }
  }

  const loadApp = useCallback(
    () => {
      dispatch(loadAppDetails({ networkID: chainID, provider }));
      dispatch(loadGovernanceDetails({ networkID: chainID, provider }));
      bonds.map(bond => {
        dispatch(calcBondDetails({ bond, value: null, provider, networkID: chainID, address }));
      });
      setIsLoad(true)
    },
    [connected, provider],
  );

  const loadAccount = useCallback(
    () => {
      dispatch(loadAccountDetails({ networkID: chainID, address, provider }));
      bonds.map(bond => {
        dispatch(calculateUserBondDetails({ address, bond, provider, networkID: chainID }));
        dispatch(calculateUserSuperBondDetails({ address, bond, provider, networkID: chainID }));
      });
    },
    [connected, provider],
  );

  const switchPerformance = () => {
    setShowPerformance(!showPerformance)
  }

  useEffect(() => {
    if (hasCachedProvider()) {
      // then user DOES have a wallet
      connect().then(() => {
        setWalletChecked(true);
      });
    } else {
      // then user DOES NOT have a wallet
      setWalletChecked(true);
    }
    storeQueryParameters();
  }, []);

  useEffect(() => {
    loadDetails("app");
    // if (walletChecked) {
    //   loadDetails("app");
    // }
  }, [walletChecked, provider]);

  useEffect(() => {
    loadDetails("account");
    // if (connected && isLoad) {
    //   loadDetails("account");
    // }
  }, [connected, isLoad, provider]);

  let themeMode = darkTheme;

  useEffect(() => {
    themeMode = darkTheme;
  }, [theme]);

  // useEffect(() => {
	// 	const address = getQueryString('address')
  //   if(location.pathname === '/ido' && isSmallerScreen && address) {
  //     setWalletVisible(true)
  //   }
  // }, [location, isSmallerScreen]);

  return (
    <ThemeProvider theme={themeMode}>
      <CssBaseline />
      {/* <ComingSoon state={showComing} closeComing={() => setShowComing(false)} /> */}
      <div className={`app ${isSmallerScreen && "app-mobile tablet"} ${isSmallScreen && "mobile"} ${theme}`}>
        <Messages />
        {!isSmallScreen && <TopMenu />}
        {isSmallScreen && <TopApp />}
        
        <div className={`app-content`}>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path="/">
                <Redirect to="/ido" />
              </Route>

              <Route path="/ido" component={Ido} />
              <Route path="/airdrop" component={Airdrop} />
              {/* <Route path="/donate" component={Donate1} />
              <Route path="/stake" component={Stake} />
              <Route path="/swap" component={Swap} />

              <Route exact path="/dashboard">
                <Dashboard showPerformance={showPerformance} />
              </Route> */}

              <Route component={NotFound} />
            </Switch>
          </Suspense>
          
          {!isSmallScreen && <Siderbar />}
          {/* {!isSmallScreen && <BotWallet />} */}
        </div>
        {isSmallScreen && <Footer />}
      </div>
    </ThemeProvider>
  );
}

export default App;
