import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { useWeb3Context } from './web3Context'
import { clearPendingTxn, fetchPendingTxns } from 'src/slices/PendingTxnsSlice';
import { error } from 'src/slices/MessagesSlice';
import { ethers } from 'ethers'
import { abi as LOCKABI } from 'src/abi/lock.json'
import { abi as erc20 } from 'src/config/abi/erc20.json'
import { addresses } from 'src/constants';
import { openWarningNotification, openSuccessNotification } from '../utils/tip.js';

export default function useInvite() {
	const dispatch = useDispatch()

	const { address, provider, chainID } = useWeb3Context()
	const [salePrice, setSalePrice] = useState()
	const [endLockTime, setEndLockTime] = useState()
	const [vestingTerm, setVestingTerm] = useState()
	const [oldBalance, setOldBalanc] = useState()
	const [swapAllowance, setSwapAllowance] = useState()
	const [users, setUsers] = useState()
	const [calculatorReward, setCalculatorReward] = useState()
	const [pendingPayoutFor, setPendingPayoutFor] = useState()
	
	// useEffect(() => {
	// 	initData()
	// }, [address, provider, chainID])

	const initData = useCallback(
		async () => {
			const lockContract = new ethers.Contract(addresses[chainID].LOCK_ADDRESS, LOCKABI, provider)
			const oldBondContract = new ethers.Contract(addresses[chainID].OLD_OHM_ADDRESS || '', erc20, provider)

			const endLockTime = await lockContract.endLockTime()
			setEndLockTime(endLockTime)

			const vestingTerm = await lockContract.vestingTerm()
			setVestingTerm(vestingTerm)

			let oldBalance = 0
			let swapAllowance = 0
			if(address) {
				oldBalance = await oldBondContract.balanceOf(address);
				setOldBalanc(oldBalance / Math.pow(10, 9))

				swapAllowance = await oldBondContract.allowance(address, addresses[chainID].LOCK_ADDRESS);
				setSwapAllowance(swapAllowance)

				const users = await lockContract.users(address)
				setUsers(users)

				const calculatorReward = await lockContract.calculatorReward(address)
				setCalculatorReward(calculatorReward / Math.pow(10, 9))

				const pendingPayoutFor = await lockContract.pendingPayoutFor(address)
				setPendingPayoutFor(pendingPayoutFor / Math.pow(10, 9))
			}
		},
		[address, provider, chainID],
	)

	const DoApprove = useCallback(
		async () => {
				if (address && provider && chainID) {
						const signer = provider.getSigner()
						const asohContract = new ethers.Contract(addresses[chainID].OLD_OHM_ADDRESS, erc20, signer)
						let tx;
						try {
								tx = await asohContract.approve(
										addresses[chainID].LOCK_ADDRESS,
										ethers.utils.parseUnits("1000000000", "gwei").toString()
								)
								dispatch(
									fetchPendingTxns({ txnHash: tx.hash, text: "Approve_IND", type: "approve_ind" })
								);
								await tx.wait()
								return tx
						} catch (e) {
								const rpcError = e;
								if (rpcError.data) {
										dispatch(error(rpcError.data.message))
								} else {
										dispatch(error(rpcError.message))
								}
						} finally {
								if (tx) {
										initData().then()
										dispatch(clearPendingTxn(tx.hash));
								}
						}
				}
		},
		[address, provider, chainID],
	)

	const DoSwap = useCallback(
		async (oldBalance) => {
				if (address && provider && chainID) {
						const signer = provider.getSigner()
						const asohContract = new ethers.Contract(addresses[chainID].LOCK_ADDRESS, LOCKABI, signer)
						let tx;
						try {
								tx = await asohContract.swap(oldBalance.toString() * Math.pow(10, 9), address)
								dispatch(
									fetchPendingTxns({ txnHash: tx.hash, text: "Approve_IND", type: "approve_ind" })
								);
								await tx.wait()
								return tx
						} catch (e) {
								const rpcError = e;
								if (rpcError.data) {
										dispatch(error(rpcError.data.message))
								} else {
										dispatch(error(rpcError.message))
								}
						} finally {
								if (tx) {
										initData().then()
										dispatch(clearPendingTxn(tx.hash));
								}
						}
				}
		},
		[address, provider, chainID],
	)

	const DoClaim = useCallback(
		async () => {
			const signer = provider.getSigner()
			const lockContract = new ethers.Contract(addresses[chainID].LOCK_ADDRESS, LOCKABI, signer)
			let tx;
			try {
				tx = await lockContract.claim(address)
				await tx.wait()
				return tx
			} catch (e) {
				if (e.data) {
					openWarningNotification(e.data.message)
				} else {
					openWarningNotification(e.message)
				}
			} finally {
				if(tx) {
					initData()
				}
			}
		},
		[address, provider, chainID, salePrice],
	)

	const DoRedeem = useCallback(
		async () => {
			const signer = provider.getSigner()
			const lockContract = new ethers.Contract(addresses[chainID].LOCK_ADDRESS, LOCKABI, signer)
			let tx;
			try {
				tx = await lockContract.redeem(address)
				await tx.wait()
				return tx
			} catch (e) {
				if (e.data) {
					openWarningNotification(e.data.message)
				} else {
					openWarningNotification(e.message)
				}
			} finally {
				if(tx) {
					initData()
				}
			}
		},
		[address, provider, chainID, salePrice],
	)

	return { endLockTime, vestingTerm, oldBalance, swapAllowance, users, calculatorReward, pendingPayoutFor, DoApprove, DoSwap, DoClaim, DoRedeem }
}
