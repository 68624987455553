import { useState } from 'react'
import { OutlinedInput, Slider } from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import './stake.scss'
import './stake.app.scss'

const CustomSlider = withStyles({
  root: {
    color: '#3880ff',
    height: 5,
    padding: '15px 0',
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '1px solid #FEBF4B',
    marginTop: -9,
    marginLeft: -10,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
    },
  },
  active: {},
  // valueLabel: {
  //   left: 'calc(-50% + 12px)',
  //   top: -22,
  //   '& *': {
  //     background: 'transparent',
  //     color: '#000',
  //   },
  // },
  track: {
    height: 5,
    backgroundColor: '#FEBF4B',
  },
  rail: {
    height: 5,
    opacity: 1,
    backgroundColor: '#303030',
  },
  // mark: {
  //   backgroundColor: '#bfbfbf',
  //   height: 8,
  //   width: 1,
  //   marginTop: -3,
  // },
  // markActive: {
  //   opacity: 1,
  //   backgroundColor: 'currentColor',
  // },
})(Slider);

export default function Stake() {

  const isSmallerScreen = useMediaQuery("(max-width: 1200px)");

  const [inputValue, setInputValue] = useState()
  const [currentTab, setCurrentTab] = useState('stake')
  const [stakeValue, setStakeValue] = useState(3)

  const stakeValueChange = (e, value) => {
    console.log(value);

    setStakeValue(value)
  }

  return (
    <div className={`${isSmallerScreen ? 'stake-view-app' : ''} stake-view`}>
      <div className='stake-title'>STAKE</div>

      <div className='bg-container stake-content'>
        <div className='content-table'>
          <div>
            <div className='title'>Collection Record</div>

            <div className="node-table">
              <div className="node-table-header">
                <div className="header-item" style={{ width: '40%' }}>Your Stake</div>
                <div className="header-item" style={{ width: '20%' }}>Pending</div>
                <div className="header-item" style={{ width: '40%' }}>Unlock</div>
              </div>

              <div className="node-table-body">
                <div className="body-item">
                  <div className="body-item-child" style={{ width: '40%' }}>SCIGR</div>
                  <div className="body-item-child" style={{ width: '20%' }}>134.15</div>
                  <div className="body-item-child" style={{ width: '40%' }}>182days, 23hrs, 36m</div>
                </div>

                <div className="body-item">
                  <div className="body-item-child" style={{ width: '40%' }}>SCIGR-USDT LP</div>
                  <div className="body-item-child" style={{ width: '20%' }}>134.15</div>
                  <div className="body-item-child" style={{ width: '40%' }}>-</div>
                </div>
              </div>
            </div>
          </div>

          <div className='claim-btn'>Claim All</div>
        </div>

        <div className='content-operate'>
          <div className='stake-tab'>
            <div className={`${currentTab === 'stake' ? 'tab-item-active' : ''} tab-item`} onClick={() => setCurrentTab('stake')}>Stake</div>
            <div className={`${currentTab === 'unstake' ? 'tab-item-active' : ''} tab-item`} onClick={() => setCurrentTab('unstake')}>Unstake</div>
          </div>

          <div className='ido-operate'>

            <div className='operate-container'>
              <div className='input-container'>
                <OutlinedInput
                  fullWidth
                  className="stake-input"
                  placeholder='0.00'
                  type="number"
                  value={inputValue}
                  onChange={e => setInputValue(e.target.value)}
                  endAdornment={
                    <div className='stake-input-end'>
                      <span>CIGR</span>
                    </div>
                  }
                />
              </div>

              <div className='my-balance'>
                Balance: 2222
              </div>
            </div>

            <div className='slider-container' style={{ paddingLeft: 6 }}>
              <CustomSlider value={stakeValue} min={3} max={365} step={1} onChange={stakeValueChange} />
            </div>

            <div className='slider-text flex flex-justify-content-between'>
              <span>3 DAY</span>
              <span>365 DAY</span>
            </div>

            <div className='approve-btn flex flex-justify-content-center flex-align-items-center'><span>Approve</span></div>

            <div className='my-staked flex flex-justify-content-between flex-align-items-center'>
              <div className='staked-item flex flex-column flex-justify-content-between flex-align-items-center'>
                <div>TOTAL STAKED</div>
                <div>$54,192,387.92</div>
              </div>

              <div className='staked-item flex flex-column flex-justify-content-between flex-align-items-center'>
                <div>ROI(3-DAY RATE)</div>
                <div>6.2313%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
